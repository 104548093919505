// @flow
/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-28 13:04:10
 */
import { getCommon } from '../../../commons/index';

import { msalInstance, loginRequest } from './AuthConfig';
import { getLanguage, LANG } from '../../../utility/languageUtility';

const baseApiUrl = process.env.REACT_APP_API_BASE_URL; // like http://192.168.0.3:3000/

function getAccount(instance, accountIdentifiers) {
  const allAccounts = instance.getAllAccounts();
  if (
    allAccounts.length > 0 &&
    (accountIdentifiers.homeAccountId ||
      accountIdentifiers.localAccountId ||
      accountIdentifiers.username)
  ) {
    const matchedAccounts = allAccounts.filter((accountObj) => {
      if (
        accountIdentifiers.username &&
        accountIdentifiers.username.toLowerCase() !==
          accountObj.username.toLowerCase()
      ) {
        return false;
      }
      if (
        accountIdentifiers.homeAccountId &&
        accountIdentifiers.homeAccountId.toLowerCase() !==
          accountObj.homeAccountId.toLowerCase()
      ) {
        return false;
      }
      if (
        accountIdentifiers.localAccountId &&
        accountIdentifiers.localAccountId.toLowerCase() !==
          accountObj.localAccountId.toLowerCase()
      ) {
        return false;
      }

      return true;
    });

    return matchedAccounts[0] || null;
  }
  return null;
}
export function redirectToLogin(reject, search) {
  let lng = getLanguage(search);
  if (lng !== LANG.EN && lng !== LANG.FR) {
    lng = LANG.EN;
  }
  loginRequest.extraQueryParameters.ui_locales = lng;
  msalInstance.loginRedirect(loginRequest).catch((error) => {
    if (reject) {
      reject(error);
    }
  });
}
export const acquireToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  const account = getAccount(msalInstance, accounts[0] || {});

  if (!account) {
    // throw new Error(
    //   'No active account! Verify a user has been
    // signed in and setActiveAccount has been called.',
    // );
    // user login, but can't find information in the cache, maybe it's deleted
    // then redirect to login
    msalInstance.logoutRedirect();
    return null;
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    return response;
  } catch (error) {
    // according to the doc here: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
    // if the session exists and acquirin token fails,
    // then throw InteractionRequiredAuthError
    // if the session doesn't exist and refresh token expires, then
    // need to login again.
    // bug 1021 happens when refresh token expires
    // but we try to access api when loading the page without logging in.
    // No matter what kind of error happens here, we should try to log in.
    // eslint-disable-next-line no-debugger
    await msalInstance.acquireTokenRedirect({
      ...loginRequest,
      account,
    });
  }
  return null;
};

// eslint-disable-next-line consistent-return
const callApiWithAzureToken = async (params) => {
  try {
    const response = await acquireToken();
    if (!response) {
      return null;
    }
    const callParam = params;
    if (!callParam.url.startsWith('http')) {
      callParam.url = baseApiUrl + callParam.url;
    }
    const bearer = `Bearer ${response.accessToken}`;
    const authorization = {
      Authorization: bearer,
    };
    callParam.headers = callParam.headers
      ? { ...callParam.headers, ...authorization }
      : authorization;

    const { callApi } = getCommon();
    const result = await callApi(callParam);
    if (result) {
      return result.data;
    }
    return null;
  } catch (error) {
    // Redirecting to login page when valid token is not present
    const err = new Error(error.message);
    throw err;
  }
};

export default callApiWithAzureToken;
