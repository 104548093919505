/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-06-01 20:43:05
 */

export function isAndroid() {
  const u = navigator.userAgent;
  const android = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  return android;
}
export function isChrome() {
  return navigator.userAgent.indexOf('Chrome') > -1;
}
export function isIos() {
  return (
    /iPad/i.test(navigator.userAgent) || /iPhone/i.test(navigator.userAgent)
  );
}
export function isIosSafari() {
  return isIos() && /WebKit/i.test(navigator.userAgent);
}

export function isEllipsisActive(element) {
  return element.offsetWidth < element.scrollWidth;
}

export function getTextWidth(
  str,
  fontSize = '18px',
  fontWeight = 'bold',
  elementName = 'h4',
) {
  const tolerance = 2;
  let result = 0;
  const ele = document.createElement(elementName);
  ele.style.position = 'absolute';
  ele.style.whiteSpace = 'nowrap';
  ele.style.fontSize = fontSize;
  ele.style.fontWeight = fontWeight;
  ele.style.opacity = 0;
  ele.innerText = str;
  document.body.append(ele);
  result = ele.getBoundingClientRect().width;
  document.body.removeChild(ele);
  return result + tolerance;
}
