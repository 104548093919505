/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-04-27 16:26:22
 */
export const LANG = {
  EN: 'en',
  FR: 'fr',
};

export const LANG_OPTIONS = {
  en: {
    locale: 'en-US',
  },
  fr: {
    locale: 'fr-CA',
  },
};

export const langURLParam = 'lang';

export const getProperty = (key, object) => {
  if (typeof key !== 'string') return key;
  const parts = key.split('.');
  let obj = { ...object };
  parts.forEach((part) => {
    if (obj[part]) {
      obj = obj[part];
    }
  });
  return obj;
};

// Checking we have a valid language id
export const getLanguageById = (id) => {
  if (!id) return undefined;
  switch (id.toLowerCase()) {
    case LANG.FR:
      return LANG.FR;
    case LANG.EN:
      return LANG.EN;
    default:
      return undefined;
  }
};

export const getLanguageFromParam = (search) => {
  if (search) {
    return getLanguageById(new URLSearchParams(search).get(langURLParam));
  }
  return undefined;
};

export const getLanguageFromBrowser = () => {
  const browserLang = navigator.language || navigator.userLanguage;
  // Slicing off the first 2 letters en or fr
  return getLanguageById(browserLang && browserLang.slice(0, 2));
};

// Gets language, from url param, browser settings, or default to english
export const getLanguage = (search) => {
  //  Assigns the first truthy value
  return getLanguageFromParam(search) || getLanguageFromBrowser() || LANG.EN;
};
