import React, { createContext, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// Adding comments here since you cant add them in JSON
// The following entries need updated FRENCH translations
// TODO: layout.nav.additionalServices
// TODO: layout.nav.consentForm
import translations from './translations.json';
import {
  LANG,
  langURLParam,
  getLanguage,
  getLanguageById,
  getProperty,
} from '../../utility/languageUtility';

// Matches text wrapped in double curly brackets
// {{Example match}}
// {{match 1}} not matched {{match 2}}
const interpolateRegex = /({{(?:(?:[^}][^}]?|[^}]}?)*)}})/g;

export const LanguageContext = createContext();

export const LanguageContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [lang, setLang] = useState(getLanguage(location.search));
  const setLangOverride = (langId) => {
    const newLang = getLanguageById(langId);
    if (newLang !== lang) {
      setLang(newLang);

      const urlParams = new URLSearchParams(location.search);
      urlParams.set(langURLParam, newLang);
      history.replace(`${location.pathname}?${urlParams.toString()}`);

      // If there is issues when switching languages, like needing to retry api
      // calls or with 3rd party libraries, enable window reload as a quick fix.
      // window.location.reload();
    }
  };

  const toggleLang = () => {
    if (lang === LANG.EN) {
      setLangOverride(LANG.FR);
    } else {
      setLangOverride(LANG.EN);
    }
  };

  const changeLanguage = useCallback((lngId) => {
    setLang(lngId);
  }, []);
  /**
   * @Author: Shaomin Fei
   * @Description: get specific sub object from translations object
   * @param {*} key
   * @return {*}
   */
  const getTranslationObj = (key) => {
    return getProperty(key, translations);
  };
  /**
   * @description Gets a language value given a key, in the current language.
   *  Supports interpolating JSX and other React nodes.
   *
   * @param {string} key
   *  Dot notation string corresponding to value in translations.json
   * @param {{ [nameMatchingValueInKey]: string || node }} interpolationValues
   *  Object with keys matching values in language key.
   *  EX:
   *    key = "Business Phone Number: {{phone}}" (Value in translation file)
   *    interpolation = { phone: <a tel="555-555-1234">555-555-1234<a/> }
   *    return
   *      [ "Business Phone Number: ", <a tel="555-555-1234">555-555-1234<a/> ]
   *
   * @returns {string || array<string || node>}
   */
  const translate = (key, interpolationValues, objFrom) => {
    // eslint-disable-next-line no-param-reassign
    if (Array.isArray(key)) [key, interpolationValues] = key;
    let property = null;
    if (objFrom) {
      property = getProperty(key, objFrom);
    } else {
      property = getProperty(key, translations);
    }
    // let property = getProperty(key, translations);
    if (!property) return property;
    let translated = property[lang] || property[LANG.EN] || key;
    const shouldTransformText =
      (typeof translated === 'string' && translated.indexOf('{{') > -1) ||
      interpolationValues;
    // Only running expensive regex if string contains curly braces
    if (shouldTransformText) {
      translated = translated.split(interpolateRegex).map((section) => {
        // Text was wrapped in curly braces, needs to be transformed
        if (section.startsWith('{{') && section.endsWith('}}')) {
          // Stripping curly braces from value
          const insert = section.substring(2, section.length - 2);
          let value = null;
          if (interpolationValues) {
            value = interpolationValues[insert];
          }

          // Is an interpolated value, such as adding a link
          if (value) {
            if (value.props && value.props.children) {
              value = {
                ...value,
                props: {
                  ...value.props,
                  children: translate(value.props.children),
                },
              };
            }
            return translate(value);
          }
          // HTML to be rendered
          if (
            typeof insert === 'string' &&
            insert.startsWith('<') &&
            insert.endsWith('>')
          ) {
            return (
              // User submitted text is not being entered here
              // It is safe from XSS attacks
              // eslint-disable-next-line react/no-danger
              <span key={insert} dangerouslySetInnerHTML={{ __html: insert }} />
            );
          }
        }
        // regular text, return it
        return section;
      });
    }
    return translated;
  };

  return (
    <LanguageContext.Provider
      value={{
        lang,
        toggleLang,
        translate,
        getTranslationObj,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageContextProvider.defaultProps = {
  children: null,
};

LanguageContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};
