/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-29 16:21:03
 */

import * as React from 'react';

import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  EventType,
  EventMessageUtils,
  InteractionStatus,
} from '@azure/msal-browser';

import AzureOperation from '../common/index';

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
export function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const comparisonArray = [...arrayB];

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift();
    if (!elementA || !elementB) {
      return false;
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    );
  });
}

export const MsaProviderContext = createContext();
export const MsalProvider = (props) => {
  const { instance } = AzureOperation;
  const [accounts, setAccounts] = useState([]);
  const [inProgress, setInProgress] = useState(InteractionStatus.Startup);
  const [loginError, setLoginError] = useState(null);
  const { children } = props;
  const isAuthenticated = () => {
    if (accounts && accounts.length > 0) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          {
            const currentAccounts = instance.getAllAccounts();
            if (!accountArraysAreEqual(currentAccounts, accounts)) {
              setAccounts(currentAccounts);
            }
            if (message.eventType === EventType.LOGIN_FAILURE) {
              setLoginError(message.error);
            }
          }
          break;
        default:
          break;
      }
    });
    return () => {
      // Remove callback when component unmounts or accounts change
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, accounts]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      const status = EventMessageUtils.getInteractionStatusFromEvent(message);
      if (status !== null) {
        setInProgress(status);
      }
    });
    instance.handleRedirectPromise().catch(() => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <MsaProviderContext.Provider
      value={{ accounts, inProgress, loginError, isAuthenticated }}
    >
      {children}
    </MsaProviderContext.Provider>
  );
};
MsalProvider.defaultProps = {
  children: null,
};
MsalProvider.propTypes = {
  children: PropTypes.element,
};
