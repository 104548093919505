import Base64 from 'base-64';
import { isIosSafari } from './osUtility';

const openPDF = async (base64PDF) => {
  const pdfString = Base64.decode(base64PDF);
  const byteNumbers = new Array(pdfString.length);
  for (let i = 0; i < pdfString.length; i += 1) {
    byteNumbers[i] = pdfString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: 'application/pdf' });
  if (!isIosSafari()) {
    window.open(URL.createObjectURL(file), '_blank');
  } else {
    // workaround for Safari on iOS to fix issue
    // where PDF is aligned to the bottom of the screen
    const safariWindow = window.open();
    await new Promise((r) => setTimeout(r, 1000));
    safariWindow.location.href = URL.createObjectURL(file);
  }
};

export default openPDF;
