// @flow
/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-28 12:29:30
 */
import Axios from 'axios';

export class CallParams {
  constructor() {
    this.url = '';

    this.method = 'get';

    this.headers = null;

    // append to the url, like {ID:123}
    this.params = null;

    // `timeout` specifies the number of milliseconds before the request
    //  times out.If the request takes longer than `timeout`, the request
    // will be aborted.0 means (no timeout)
    this.timeout = 30000;

    this.responseType = 'json';

    // body like post, put
    this.data = null;
  }
}

const wrapperAxios = async (params) => {
  const result = await Axios(params);
  return result;
};

/**
 * @Author: Shaomin Fei
 * @Description:
 * @param {CallParams} params Basic params, url and method are mandotory.
 * @param {Object} extraParams Extra params, like configuration of axios.
 * see:https://github.com/axios/axios.
 * @return {Promise<any>} return result of call
 */
export const callApi = async (params, extraParams) => {
  if (!params || !params.url || !params.method) {
    throw new Error(
      "parameter is not completed, url, method shouldn't be null",
    );
  }
  try {
    let config = params;
    if (!extraParams) {
      config = { ...config, ...extraParams };
    }
    const result = await wrapperAxios(config);
    return result;
  } catch (error) {
    if (!Axios.isCancel(error)) {
      throw new Error(error.message);
    }
    throw new Error('cancel');
  }
};
