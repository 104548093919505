/*
 * @Description:
 * @Author: Shaomin Fei
 * @Date: 2021-03-30 09:41:21
 */

import { CallParams, callApi } from './net/CallApiHelper';
import pagesUrl, { getGroup } from './config/PagesUrl';

const Common = {
  CallParams,
  callApi,
  pagesUrl,
  getGroup,
};
export default Common;
export const getCommon = () => Common;
